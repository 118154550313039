<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="8" class="pa-0">
      <v-card
        class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
        max-width="100%"
        outlined
      >
        <CardToolbar
          @add-data="openCrudDialog({ item: $event, action: CREATE })"
          @refresh-data="refreshEntries()"
          button-mode
          :disableAdd="loading"
          :disableRefresh="loading"
          :icon="TABLE_ICON"
          include-add
          include-help
          include-refresh
          title="Valid Tables"
        />
        <v-card-text class="mt-n4 mb-0">
          <p :class="`${DEFAULT_TEXT} mb-0`">View and modify various types of valid data.</p>
        </v-card-text>

        <HelpText :page="VALID_TABLES_VUE" />

        <v-data-table
          id="valid-table-table"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
          :headers="headers"
          :height="500"
          :items-per-page="25"
          :items="selectedTable[DATA]"
          :loading="loading"
          :search="search"
          @click:row="openCrudDialog({ item: $event, action: UPDATE})"
          class="mx-4"
          fixed-header
          loading-text="Loading, please wait..."
          no-data-text="Table contains no data."
          no-results-text="No results found."
          sort-by="id"
        >
          <template v-slot:top>
            <v-row
              :class="isMediumBreakpointOrLarger ? 'mb-0' : 'mb-4'"
              dense
            >
              <v-col class="d-flex align-top" cols="12" md="6">
                <v-select
                  v-model="selection"
                  label="Select a valid table..."
                  :items="validTableNames"
                />
              </v-col>
              <v-col class="d-flex align-top" cols="12" md="6">
                <v-text-field
                  v-model="search"
                  clearable
                  hide-details
                  label="Search"
                  :prepend-icon="SEARCH_ICON"
                  single-line
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="item.team_name != 'None'"
              class="error--text"
              @click.stop="openConfirmationDialog(DELETE, item)"
            >
              {{ DELETE_ICON }}
            </v-icon>
          </template>
        </v-data-table>

        <DialogConfirmation
          :dialog.sync="dialog[CONFIRMATION].show"
          @confirmed="deleteEntryByDataType(dialog[CONFIRMATION].config.type, dialog[CONFIRMATION].payload)"
          :config="dialog[CONFIRMATION].config"
          :payload="dialog[CONFIRMATION].payload"
        />

        <DialogCrud
          :dialog.sync="dialog[CRUD].show"
          @save-data="saveEntry(dialog[CRUD].config.type, dialog[CRUD].config.action, dialog[CRUD].payload)"
          :config="dialog[CRUD].config"
          :payload="dialog[CRUD].payload"
          :saving="saving"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep, forEach, map, omit, snakeCase, startCase } from 'lodash'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import { DIALOGS, ACTIONS, COLUMNS, VUES, TEXT_COLOR, ICONS, DATA, MESSAGE_TYPE, METADATA, PRIMARY_KEY, VALID_TABLES, } from '../constants'
import { FIELD_METADATA } from '../models/dto/ValidTable'
import { now } from '../services/utility.service'
import { RULES_TYPES } from '../models/validation_rules/ValidationRulesConfig'

import CardToolbar from '../components/layout/toolbars/CardToolbar.vue'
import DialogConfirmation from '../components/layout/dialog/DialogConfirmation.vue'
import DialogCrud from '../components/layout/dialog/DialogCrud.vue'
import HelpText from '../components/layout/HelpText.vue'

export default {
  components: {
    CardToolbar,
    DialogConfirmation,
    DialogCrud,
    HelpText,
  },
  data: () => ({
    // Dialog window state.
    dialog: {
      [DIALOGS.CONFIRMATION]: { config: {}, payload: {}, show: false, },
      [DIALOGS.CRUD]: { config: {}, payload: {}, show: false, },
    },
    loading: false,
    saving: false,
    search: null,
    selection: '',
    selectedTable: {
      name: {
        database: '',
        display: ''
      },
      [DATA]: [],
      [METADATA]: [],
    },
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      getData: 'valid/getData',
    }),
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    headers () {
      if (this.selectedTable[METADATA].length === 0) {
        return []
      }

      let fieldGroup = this.selectedTable[METADATA].fieldGroups[0]
      
      let headerConfig = map(fieldGroup, field => {
        return {
          text: startCase(field.property),
          align: (field.type === [RULES_TYPES.INT]) ? 'right' : 'left',
          value: field.property
        }
      })

      headerConfig.push({ text: 'Actions', value: 'actions', align: 'center', sortable: false })

      return headerConfig 
    },
    isMediumBreakpointOrLarger () {
      return this.breakpoint !== 'sm' && this.breakpoint !== 'xs'
    },
    validTableNames () {
      return map(VALID_TABLES, table => startCase(table))
    },
  },
  methods: {
    ...mapActions({
      enqueueMessage: 'message/enqueueMessage',
      errorMessage: 'message/errorMessage',

      initValidStore: 'valid/initStore',
      createData: 'valid/createData',
      deleteData: 'valid/deleteData',
      fetchList: 'valid/fetchList',
      updateData: 'valid/updateData',
    }),
    ...mapMutations({
      clearAgentCache: 'agent/clearCache',
      clearClientCache: 'client/clearCache',
      clearMortgageDealCache: 'mortgageDeal/clearCache',
    }),
    /**
     * Appends metadata required by the database (operator id, date-time) to the payload.
     * @param {*} payload Data to send to database.
     * @returns Payload appended with required metadata.
     */
    appendMetadata (payload) {
      payload['operator_id'] = this.user.id
      payload['dlc'] = now()

      return payload
    },
    /**
     * Clear the model caches that depend on this data so that the valid tables can be fetched again.
     * @param {string} type Valid table data type.
     */
    clearCaches (type) {
      switch (type) {
        case VALID_TABLES.AGENT_TEAM:
        case VALID_TABLES.AGENT_PROFILE:
        case VALID_TABLES.SOCIAL_MEDIA:
          this.clearAgentCache()
          break
        case VALID_TABLES.COMMENT_TYPE:
        case VALID_TABLES.LANGUAGE:
        case VALID_TABLES.MORTGAGE_DEAL_STATUS:
        case VALID_TABLES.PROGRAM:
          this.clearMortgageDealCache()
          break
        case VALID_TABLES.PROVINCE:
          this.clearMortgageDealCache()
          this.clearAgentCache()
          this.clearClientCache()
          break
        default:
          // Do not need to clear caches.
      }

      if (type === VALID_TABLES.AGENT_TEAM) {
        this.clearAgentCache()
      }

      
    },
    /**
     * Calls API to make a DELETE request for the given data type and payload.
     * @param {string} type Valid table data type.
     * @param {*} payload Data to delete.
     */
    deleteEntryByDataType (type, payload) {
      this.dialogClose(DIALOGS.CONFIRMATION)

      let primaryKeyField = this.selectedTable[METADATA][PRIMARY_KEY]

      let request = {
        type,
        primary_key: payload[primaryKeyField]
      }

      this.deleteData(request)
        .then(() => {
          this.refreshEntries()
          this.enqueueMessage({ action: ACTIONS.DELETE, type: MESSAGE_TYPE.SUCCESS, data: payload, dataType: type })
          this.clearCaches(type)
        })
        .catch(error => {
          this.enqueueMessage({ action: ACTIONS.DELETE, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
        })
    },
    /**
     * Close and reset a dialog window.
     * @param {string} type Dialog window type.
     */
    dialogClose (type) {
      this.dialog[type] = { show: false, config: {}, payload: {}, }
    },
    /**
     * Initialize the constants used in this view.
     */
    initTemplateConstants () {
      this.CONFIRMATION = DIALOGS.CONFIRMATION
      this.CRUD = DIALOGS.CRUD

      this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT

      this.DELETE_ICON = ICONS.DELETE
      this.DROPDOWN_ICON = ICONS.DROPDOWN
      this.SEARCH_ICON = ICONS.SEARCH
      this.TABLE_ICON = ICONS.TABLE

      this.VALID_TABLES_VUE = VUES.VALID_TABLES

      this.CREATE = ACTIONS.CREATE
      this.DELETE = ACTIONS.DELETE
      this.UPDATE = ACTIONS.UPDATE

      this.COLUMNS = COLUMNS
      this.DATA = DATA
      this.METADATA = METADATA
    },
    /**
     * Initialize the names, data, and metadata for the currently selected valid table.
     */
    initSelectedTable () {
      this.selectedTable.name.database = snakeCase(this.selection),
      this.selectedTable.name.display = startCase(this.selection),

      this.selectedTable[DATA] = this.getData(this.selectedTable.name.database)
      this.selectedTable[METADATA] = FIELD_METADATA[this.selectedTable.name.database]
    },
    async initStore () {
      try {
        this.loading = true
        await this.initValidStore()
        this.loading = false
      }
      catch (error) {
        this.errorMessage('Failed to initialize valid data store.')
      }
    },
    /**
     * Opens the confirmation dialog box and configures it based on the action and data type being
     * confirmed.
     * @param {string} action Action to confirm
     * @param {*} payload Data that is acted upon
     */
    openConfirmationDialog (action, payload) {
      this.dialog[DIALOGS.CONFIRMATION].payload = payload

      const field = this.selectedTable[METADATA][PRIMARY_KEY]
      let target = `${payload[field]}`

      switch (action) {
        case ACTIONS.DELETE:
          this.dialog[DIALOGS.CONFIRMATION].config = {
            action,
            type: this.selectedTable.name.database,
            title: 'Delete Confirmation',
            message: `Delete this from ${this.selection}?`,
            target,
          }
          break
      }

      this.dialog[DIALOGS.CONFIRMATION].show = true
    },
    /**
     * Initializes and opens the dialog window with default values set for the fields based on the
     * currently selected valid table.
     * @param {string} action API action that is taking place: CREATE or UPDATE.
     * @param {*} item On UPDATE action, the object being updated. On CREATE action, null.
     */
    openCrudDialog ({ action, item }) {
      if (!item || item.team_name !== 'None') {
        this.dialog[DIALOGS.CRUD].config = {
          action,
          type: this.selectedTable.name.database,
          fields: this.selectedTable[METADATA],
        }

        switch (action) {
          case ACTIONS.CREATE:
            forEach(this.selectedTable[METADATA].fieldGroups[0], field => {
              this.dialog[DIALOGS.CRUD].payload[field.property] = ''
            })
            this.dialog[DIALOGS.CRUD].config.title = `Add ${this.selectedTable.name.display}`
            break
          case ACTIONS.UPDATE:
            this.dialog[DIALOGS.CRUD].payload = cloneDeep(omit(item, ['dlc', 'operator_id']))
            this.dialog[DIALOGS.CRUD].config.title = `Edit ${this.selectedTable.name.display}`
            break
        }

        this.dialog[DIALOGS.CRUD].show = true
      }
    },
    /**
     * Asynchronously refreshes the table data for the currently selected valid table.
     */
    refreshEntries () {
      this.loading = true

      const request = {
        type: this.selectedTable.name.database,
        force: true
      }

      this.fetchList(request)
        .then(() => {
          this.selectedTable[DATA] = this.getData(this.selectedTable.name.database)
        })
        .catch(error => {
          this.errorMessage('Failed to refresh valid tables.')
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * Asynchronously send CREATE and UPDATE API requests for the payload and generate messages
     * based on its outcome.
     * @param {string} type Valid table data type being saved
     * @param {string} action API action (CREATE or UPDATE)
     * @param {*} payload An object formatted for the valid table API
     */
    saveEntry (type, action, payload) {
      this.saving = true

      payload = this.appendMetadata(payload)

      let request = { type, payload }

      if (action === ACTIONS.UPDATE) {
        // Save the original primary key in the event it is changed.
        // NOTE: Currently, changing the primary key is disabled in the valid tables.
        let primaryKeyField = this.selectedTable[METADATA][PRIMARY_KEY]
        request.primary_key = payload[primaryKeyField]

        this.updateData(request)
          .then(() => {
            this.refreshEntries()
            this.enqueueMessage({ action, type: MESSAGE_TYPE.SUCCESS, data: payload, dataType: type })
            this.clearCaches(type)
          })
          .catch(error => {
            this.enqueueMessage({ action, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
          })
          .finally(() => {
            this.saving = false
            this.dialogClose(DIALOGS.CRUD)
        })
      }
      else if (action === ACTIONS.CREATE) {
        this.createData(request)
          .then(() => {
            this.refreshEntries()
            this.enqueueMessage({ action, type: MESSAGE_TYPE.SUCCESS, data: payload, dataType: type })
            this.clearCaches(type)
          })
          .catch(error => {
            this.enqueueMessage({ action, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
          })
          .finally(() => {
            this.saving = false
            this.dialogClose(DIALOGS.CRUD)
        })
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.dialogClose(DIALOGS.CRUD)
    },
    selection() {
      this.initSelectedTable()
    }
  },
  async created () {
    this.initTemplateConstants()
    await this.initStore()
    this.selection = this.validTableNames[0]
  }
}
</script>

<style scoped>
button {
  outline: none;
} 

#data-table-toolbar >>> .v-toolbar__content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#valid-table-table {
  cursor: pointer;
}
</style>