<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    :fullscreen="isXsBreakpoint"
    @click:outside="close()"
    @keydown.esc="close()"
    max-width="600px"
  >
    <v-card
      class="pb-2 px-0"
      tile
    >
      <v-overlay
        absolute
        color="success"
        :value="saving"
      >
        <v-progress-circular
          indeterminate
          color="white"
          size="64"
        ></v-progress-circular>
      </v-overlay>

      <template slot="progress">
        <v-progress-linear
          color="success"
          indeterminate
        ></v-progress-linear>
      </template>

      <card-toolbar
        :disable-save="!valid"
        :title="config.title"
        @close="close()"
        @save-data="$emit('save-data')"
        button-mode
        class="mx-3 my-0 pt-4"
        include-close
        include-save
      />
      <v-card-text class="pb-2 pt-8 px-4">
        <v-container class="px-2 py-0">
          <v-form
            v-model="valid"
            @submit.prevent
          >
            <slot name="before-fields"></slot>
            <v-row
              v-for="(fieldGroup, i) in config.fields.fieldGroups"
              :key="i"
            >
              <!-- Add the field group title for this field group if one exists. -->
              <v-col
                v-if="config.fields.fieldGroupTitles"
                class="mx-1"
                cols="12"
              >
                <row-subheader
                  v-if="config.fields.fieldGroupTitles[i] !== null"
                >
                  {{ config.fields.fieldGroupTitles[i] }}
                </row-subheader>
              </v-col>

              <!-- Add the fields for this field group. -->
              <v-col
                v-for="(field, j) in fieldGroup" :key="j"
                cols="12"
                dense
              >
                <v-text-field
                  v-if="field.fieldType === TEXT"
                  v-model="payload[field.property]"
                  :color="fieldColor"
                  :counter="field.counter"
                  :disabled="field.property === config.fields.primary_key && isUpdateAction"
                  :label="field.label"
                  :rules="field.rules"
                  @keypress.enter="valid && $emit('save-data')"
                  class="mx-1"
                  dense
                />
                <v-select
                  v-else-if="field.fieldType === SELECT && field.items"
                  v-model="payload[field.property]"
                  :color="fieldColor"
                  :disabled="field.property === config.fields.primary_key && isUpdateAction"
                  :item-text="(field.textProperty !== null) ? field.textProperty : null"
                  :item-value="(field.textProperty !== null) ? field.textProperty : null"
                  :items="field.items"
                  :label="field.label"
                  :prepend-icon="field.prependIcon"
                  class="mx-1"
                  dense
                />
                <text-field-date-picker
                  v-else-if="field.fieldType === DATE"
                  v-model="payload[field.property]"
                  dense
                  :disabled="field.property === config.fields.primary_key && isUpdateAction"
                  :label="field.label"
                  :clearable="true"
                  :readonly="false"
                  :rules="field.rules"
                />
              </v-col>
            </v-row>
            <slot name="after-fields"></slot>
          </v-form>
        </v-container>
      </v-card-text>
      <card-toolbar
        v-if="scrollable"
        :disable-save="!valid"
        @close="close()"
        @save-data="$emit('save-data')"
        button-mode
        class="mx-3 my-0 pb-4"
        include-close
        include-save
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { COLOR, ACTIONS } from '../../../constants'
import { FIELD_TYPES } from '../../../models/field_configs/FieldConfig'

export default {
  components: {
    CardToolbar: () => import('../toolbars/CardToolbar.vue'),
    RowSubheader: () => import('../RowSubheader.vue'),
    TextFieldDatePicker: () => import('../../input/TextFieldDatePicker.vue'),
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    },
    payload: {
      type: Object,
      required: true
    },
    saving: {
      type: Boolean,
      required: true
    },
  },
  data: () => ({
    scrollable: false,
    valid: false,
  }),
  computed: {
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    fieldColor () {
      return this.saving ? COLOR.SUCCESS : COLOR.PRIMARY
    },
    isUpdateAction () {
      return this.config.action === ACTIONS.UPDATE
    },
    isXsBreakpoint () {
      return this.breakpoint === 'xs'
    },
  },
  methods: {
    close () {
      this.$emit('update:dialog', false)
    },
    initTemplateConstants () {
      this.DATE = FIELD_TYPES.DATE
      this.SELECT = FIELD_TYPES.SELECT
      this.TEXT = FIELD_TYPES.TEXT
    },
  },
  created () {
    this.initTemplateConstants()
  },
  beforeUpdate () {
    const element = document.getElementsByClassName('v-dialog--active')[0]
    if (!!element) {
      this.scrollable = element.scrollHeight > element.clientHeight
      return
    }
    this.scrollable = false
  }
}
</script>