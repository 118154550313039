import FieldConfigFactory from "../field_configs/FieldConfigFactory"
import ValidationRulesFactory from "../validation_rules/ValidationRulesFactory"
import { PRIMARY_KEY, VALID_TABLES, } from "../../constants"

const PROPERTY = {
  COMMENT_TYPE: 'comment_type',
  DESCRIPTION: 'description',
  FREQUENCY: 'frequency',
  LANGUAGE: 'language',
  LOGO_FILE: 'logo_file',
  PROFILE_ID: 'profile_id',
  PROGRAM: 'program',
  PROGRAM_ELEMENT: 'program_element',
  PROVINCE: 'province',
  SOCIAL_MEDIA: 'social_media',
  STATUS: 'status',
  TEAM_NAME: 'team_name',
}

// Validation rules applied to form fields and CSV files.
const VALIDATION_RULES = {
  [VALID_TABLES.AGENT_TEAM]: {
    [PROPERTY.TEAM_NAME]: ValidationRulesFactory.makeStringRules({ length: 70, primaryKey: true, }),
    [PROPERTY.LOGO_FILE]: ValidationRulesFactory.makeStringRules({ length: 50, nullable: true, }),
  },
  [VALID_TABLES.AGENT_PROFILE]: {
    [PROPERTY.PROFILE_ID]: ValidationRulesFactory.makeStringRules({ length: 2, primaryKey: true, }),
    [PROPERTY.DESCRIPTION]: ValidationRulesFactory.makeStringRules({ length: 50, }),
  },
  [VALID_TABLES.COMMENT_TYPE]: {
    [PROPERTY.COMMENT_TYPE]: ValidationRulesFactory.makeStringRules({ length: 10, primaryKey: true, }),
    [PROPERTY.DESCRIPTION]: ValidationRulesFactory.makeStringRules({ length: 50, }),
  },
  [VALID_TABLES.LANGUAGE]: {
    [PROPERTY.LANGUAGE]: ValidationRulesFactory.makeStringRules({ length: 2, primaryKey: true, }),
    [PROPERTY.DESCRIPTION]: ValidationRulesFactory.makeStringRules({ length: 25, }),
  },
  [VALID_TABLES.MORTGAGE_DEAL_STATUS]: {
    [PROPERTY.STATUS]: ValidationRulesFactory.makeStringRules({ length: 25, primaryKey: true, }),
    [PROPERTY.DESCRIPTION]: ValidationRulesFactory.makeStringRules({ length: 100, }),
  },
  [VALID_TABLES.PROGRAM]: {
    [PROPERTY.PROGRAM]: ValidationRulesFactory.makeStringRules({ length: 25, primaryKey: true, }),
    [PROPERTY.DESCRIPTION]: ValidationRulesFactory.makeStringRules({ length: 300, }),
  },
  [VALID_TABLES.PROGRAM_ELEMENT]: {
    [PROPERTY.PROGRAM_ELEMENT]: ValidationRulesFactory.makeStringRules({ length: 25, primaryKey: true, }),
    [PROPERTY.DESCRIPTION]: ValidationRulesFactory.makeStringRules({ length: 100, }),
  },
  [VALID_TABLES.PROGRAM_ELEMENT_FREQUENCY]: {
    [PROPERTY.FREQUENCY]: ValidationRulesFactory.makeStringRules({ length: 1, primaryKey: true, }),
    [PROPERTY.DESCRIPTION]: ValidationRulesFactory.makeStringRules({ length: 25, }),
  },
  [VALID_TABLES.PROVINCE]: {
    [PROPERTY.PROVINCE]: ValidationRulesFactory.makeStringRules({ length: 2, primaryKey: true, }),
    [PROPERTY.DESCRIPTION]: ValidationRulesFactory.makeStringRules({ length: 30, }),
  },
  [VALID_TABLES.SOCIAL_MEDIA]: {
    [PROPERTY.SOCIAL_MEDIA]: ValidationRulesFactory.makeStringRules({ length: 25, primaryKey: true, }),
  },
}

/**
 * Get the length rule value from a validation rule.
 * @param {string} type MortgageDeal.TYPE
 * @param {*} property MortgageDeal.VALIDATE_PROPERTY
 * @returns Length rule value.
 */
const getLength = (type, property) => {
  if ('length' in VALIDATION_RULES[type][property]) {
    return VALIDATION_RULES[type][property].length
  }
  throw new ReferenceError(`Rule for ${type}.${property} does not have a 'length' property.`)
}

/**
 * Get rules array from a validation rule.
 * @param {string} type MortgageDeal.TYPE
 * @param {*} property MortgageDeal.VALIDATE_PROPERTY
 * @returns Length rule value.
 */
const getRules = (type, property) => {
  if ('rules' in VALIDATION_RULES[type][property]) {
    return VALIDATION_RULES[type][property].rules
  }
  throw new ReferenceError(`Rule for ${type}.${property} does not have a 'rules' property.`)
}

/**
 * Get specific rules for a text field config.
 * @param {string} type MortgageDeal.TYPE
 * @param {*} property MortgageDeal.VALIDATE_PROPERTY
 * @returns Object containing the counter and rules for a text field.
 */
const getTextRules = (type, property) => {
  return {
    property,
    counter: getLength(type, property),
    rules: getRules(type, property),
  }
}


// MortgageDeal field metadata.
export const FIELD_METADATA = {
  [VALID_TABLES.AGENT_TEAM]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.AGENT_TEAM, PROPERTY.TEAM_NAME), label: 'Team Name', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.AGENT_TEAM, PROPERTY.LOGO_FILE), label: 'Logo File', required: false, }),
      ],
    ],
    [PRIMARY_KEY]: PROPERTY.TEAM_NAME,
  },
  [VALID_TABLES.AGENT_PROFILE]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.AGENT_PROFILE, PROPERTY.PROFILE_ID), label: 'Profile ID', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.AGENT_PROFILE, PROPERTY.DESCRIPTION), label: 'Description', }),
      ],
    ],
    [PRIMARY_KEY]: PROPERTY.PROFILE_ID,
  },
  [VALID_TABLES.COMMENT_TYPE]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.COMMENT_TYPE, PROPERTY.COMMENT_TYPE), label: 'Comment Type', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.COMMENT_TYPE, PROPERTY.DESCRIPTION), label: 'Description', }),
      ],
    ],
    [PRIMARY_KEY]: PROPERTY.COMMENT_TYPE,
  },
  [VALID_TABLES.LANGUAGE]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.LANGUAGE, PROPERTY.LANGUAGE), label: 'Language', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.LANGUAGE, PROPERTY.DESCRIPTION), label: 'Description', }),
      ],
    ],
    [PRIMARY_KEY]: PROPERTY.LANGUAGE,
  },
  [VALID_TABLES.MORTGAGE_DEAL_STATUS]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.MORTGAGE_DEAL_STATUS, PROPERTY.STATUS), label: 'Status', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.MORTGAGE_DEAL_STATUS, PROPERTY.DESCRIPTION), label: 'Description', }),
      ],
    ],
    [PRIMARY_KEY]: PROPERTY.STATUS,
  },
  [VALID_TABLES.PROGRAM]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.PROGRAM, PROPERTY.PROGRAM), label: 'Program', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.PROGRAM, PROPERTY.DESCRIPTION), label: 'Description', }),
      ],
    ],
    [PRIMARY_KEY]: PROPERTY.PROGRAM,
  },
  [VALID_TABLES.PROGRAM_ELEMENT]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.PROGRAM_ELEMENT, PROPERTY.PROGRAM_ELEMENT), label: 'Program Element', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.PROGRAM_ELEMENT, PROPERTY.DESCRIPTION), label: 'Description', }),
      ],
    ],
    [PRIMARY_KEY]: PROPERTY.PROGRAM_ELEMENT,
  },
  [VALID_TABLES.PROGRAM_ELEMENT_FREQUENCY]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.PROGRAM_ELEMENT_FREQUENCY, PROPERTY.FREQUENCY), label: 'Program Element Frequency', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.PROGRAM_ELEMENT_FREQUENCY, PROPERTY.DESCRIPTION), label: 'Description', }),
      ],
    ],
    [PRIMARY_KEY]: PROPERTY.FREQUENCY,
  },
  [VALID_TABLES.PROVINCE]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.PROVINCE, PROPERTY.PROVINCE), label: 'Province', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.PROVINCE, PROPERTY.DESCRIPTION), label: 'Description', }),
      ],
    ],
    [PRIMARY_KEY]: PROPERTY.PROVINCE,
  },
  [VALID_TABLES.SOCIAL_MEDIA]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(VALID_TABLES.SOCIAL_MEDIA, PROPERTY.SOCIAL_MEDIA), label: 'Social Media', }),
      ],
    ],
    [PRIMARY_KEY]: PROPERTY.SOCIAL_MEDIA,
  },
}
